<template>
  <b-alert :show="listed" class="listed-message-banner d-flex align-items-center mb-4 d-flex align-items-center justify-content-between px-4">
    <span class="listed-message-content font-weight-thin text-left">
      Sorry, you're unable to update details and photos while your car is
      listed. If you experience any issues, please contact us.
    </span>
    <u class="font-weight-thin text-right" v-b-modal.modal-issue>Contact us</u>
  </b-alert>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ListedCarMessage",
  computed: {
    ...mapState({
      listed: (state) => state.authenticateSeller.listed,
    }),
  },
};
</script>
<style lang="scss">
.listed-message-banner {
  color: #F0F1EC !important;
  background-color: #2E312E !important;
  box-shadow: 0px 4px 8px 3px #00000026;
  height: 104px;
  border-radius: 8px;
  span {
    font-size: 14px;
  }
  u {
    text-decoration: none;
    color: #78DAA0;
    font-size: 16px;
  }
  font-family: "Avenir Next LT W05 Regular";
}

@media screen and(min-width: 768px) {
  .listed-message-banner {
    height: 90px !important;
    span {
      width: 750px !important;
      font-size: 16px !important;
    }
    u {
      width: 220px !important;
    }
  }
}
@media screen and(max-width: 768px) {
  .listed-message-banner {
    u {
      width: 300px !important;
    }
  }
}
@media screen and(max-width: 460px) {
  .listed-message-banner {
    span {
      font-size: 12px !important;
    }
    u {
      font-size: 14px !important
    }
  }
}
</style>
